<template>
  <div>
    <a-form layout="inline">
      <a-form-item>
        <a-radio-group v-model="recordCount" @change="onChangeRecordCount">
          <a-radio :value="0"> 全部 </a-radio>
          <a-radio :value="1"> 记录数不为0 </a-radio>
          <a-radio :value="2"> 记录数为0 </a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item>
        <a-select
          v-model="defaultOptionValue"
          style="width: 120px"
          @change="handleSelectChange"
        >
          <a-select-option v-for="option in selectOptions" :key="option.value">
            {{ option.value }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
    <ve-pie
      :data="chartData"
      :settings="settings"
      height="500px"
      :resizeable="true"
      :legend="legend"
    ></ve-pie>
  </div>
</template>

<script>
import { SearchNoteModel } from "../../models/searchNote";
const searchNoteModel = new SearchNoteModel();
export default {
  name: "SearchNoteAnalysis",
  data() {
    return {
      recordCount: 0,
      defaultOptionValue: 10,
      chartData: {
        columns: ["关键字", "搜素次数"],
        rows: [],
      },
      settings: {
        label: "",
        radius: 150,
        offsetY: "center",
        // limitShowNum: 30,
      },
      selectOptions: [
        { value: 10 },
        { value: 20 },
        { value: 30 },
        { value: 40 },
        { value: 50 },
      ],
      legend: {
        // type: "scroll",
        orient: "vertical",
        right: "3%",
        top: "5%",
        bottom: 50,
        data: [],
      },
    };
  },
  methods: {
    onChangeRecordCount() {
      this.fetchChartData();
    },
    handleSelectChange(value) {
      this.defaultOptionValue = value;
      this.fetchChartData();
    },
    fetchChartData() {
      this.loading = true;
      searchNoteModel
        .getSearchNoteChartData(this.recordCount, this.defaultOptionValue)
        .then((res) => {
          if (res.code == 0) {
            this.chartData.rows = res.data;
            this.legend.data = res.columns;
          }
        });
      return this.defaultOptionValue;
    },
  },
  mounted() {
    this.fetchChartData();
  },
};
</script>

<style scoped>
</style>