<template>
  <a-tabs type="card" class="sm-mt-20">
    <a-tab-pane key="1" tab="基本信息">
      <a-form layout="inline" :form="form1" @submit="handleSearch">
        <a-form-item>
          <a-input-number
            v-decorator="['recordCount']"
            placeholder="记录数"
            :min="0"
          >
          </a-input-number>
        </a-form-item>
        <a-form-item>
          <a-range-picker
            :disabled-date="disabledDate"
            @change="onChange"
            :locale="locale"
            v-decorator="['date']"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item>
          <a-button type="primary" html-type="submit"> 搜索 </a-button>
        </a-form-item>
        <a-form-item>
          <a-button type="danger" @click="handleClear" html-type="button">
            清空
          </a-button>
        </a-form-item>
      </a-form>
      <a-table
        :columns="columns"
        :dataSource="data"
        :pagination="false"
        :loading="loading"
      >
      </a-table>
      <Pagination
        :current="current"
        :total="total"
        @handleChange="handleChange"
        v-show="total > 20"
      />
    </a-tab-pane>
    <a-tab-pane key="2" tab="数据分析">
      <search-note-analysis></search-note-analysis>
    </a-tab-pane>
  </a-tabs>
</template>

<script>
import { SearchNoteModel } from "../../models/searchNote";
import Pagination from "../../components/Pagination";
import formatTime from "../../utils/formatTime";
import userNameDisplay from "../../utils/userNameDisplay";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
const searchNoteModel = new SearchNoteModel();
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");
import SearchNoteAnalysis from "./SearchNoteAnalysis";
const columns = [
  {
    title: "序列号",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "搜索内容",
    dataIndex: "keyword",
    key: "keyword",
  },
  {
    title: "记录数",
    dataIndex: "count",
    key: "count",
  },
  {
    title: "搜索类型",
    dataIndex: "search_type",
    key: "search_type",
  },
  {
    title: "用户",
    dataIndex: "user_name",
    key: "user_name",
  },
  {
    title: "搜索时间",
    dataIndex: "created_at",
    key: "created_at",
  },
];
export default {
  data() {
    return {
      form1: this.$form.createForm(this, {
        name: "horizontal_login",
      }),
      data: [],
      columns,
      loading: false,
      total: 0,
      locale,
      date1: "",
      date2: "",
      current: 1,
    };
  },

  created() {
    this.queryNoteList();
  },

  methods: {
    disabledDate(current) {
      // Can not select days before today and today
      return current && current > moment().endOf("day");
    },

    onChange(date, dateString) {
      this.date1 = dateString[0];
      this.date2 = dateString[1];
    },

    handleSearch(e) {
      e.preventDefault();
      this.form1.validateFields((err, values) => {
        if (!err) {
          this.queryNoteList(this.date1, this.date2, values.recordCount, 1);
        }
      });
    },

    handleClear() {
      this.form1.resetFields();
      this.queryNoteList();
    },

    handleChange(page) {
      this.current = page;
      this.form1.validateFields((err, values) => {
        if (!err) {
          this.queryNoteList(this.date1, this.date2, values.recordCount, page);
        }
      });
    },

    queryNoteList(date1, date2, recordCount, page) {
      this.loading = true;
      searchNoteModel.index(date1, date2, recordCount, page).then((res) => {
        if (res.code == 0) {
          this.current = page;
          res.result.map((item, index) => {
            item.key = index;
            item.created_at = formatTime(item.created_at);
            if (item.user) {
              item.user_name = userNameDisplay(item.user);
            } else {
              item.user_name = "游客";
            }
            item.search_type = item.search_type === 'document' ? '文档' : '软件'
            return item;
          });
          this.data = res.result;
          this.loading = false;
          this.total = res.total;
        }
      });
    },
  },

  components: {
    Pagination,
    SearchNoteAnalysis,
  },
};
</script>

<style lang="scss" scoped>
</style>