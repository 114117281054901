import {HTTP} from '../utils/axios'

class SearchNoteModel extends HTTP {
  /*首页渲染*/
  index(date1 = '', date2 = '',recordCount, page = 1) {
    return this.request({
        url: 'board/search_notes',
        method: "GET",
        data: {
            date1: date1,
            date2: date2,
            record_count: recordCount,
            page: page
        }
    })
  }
  // 获取搜素记录图表数据
  getSearchNoteChartData(recordCount,limitCount) {
      return this.request({
          url: 'board/search_notes/analysis_chart_data',
          method: "GET",
          data: {
              record_count:recordCount,
              limit_count: limitCount
          }
      })
  }
}

export {SearchNoteModel}